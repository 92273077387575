import {useIsSmallDesktop} from '@prescriberpoint/ui';
import {useRouter} from 'next/router';
import {FC, memo} from 'react';
import PdpLayout from './PdpLayout';
import {
  PharmacyContextProvider,
  MobileContentProvider,
  useUserAgentContext,
} from '@/context';
import {useUser} from '@/hooks';
import {UserPanelType} from '@/interfaces/userMenu';
interface PdpLayoutWrapperProps {
  children: React.ReactNode;
}

const PdpLayoutWrapper: FC<PdpLayoutWrapperProps> = ({children}) => {
  const router = useRouter();
  const {panel} = router.query;

  const {isMobileOrTablet} = useUserAgentContext();
  const isSmallDesktop = useIsSmallDesktop();
  const {logged} = useUser();

  return (
    <PharmacyContextProvider>
      {isMobileOrTablet || (!logged && isSmallDesktop) ? (
        <MobileContentProvider userPanel={panel as UserPanelType}>
          <PdpLayout>{children}</PdpLayout>
        </MobileContentProvider>
      ) : (
        <PdpLayout>{children}</PdpLayout>
      )}
    </PharmacyContextProvider>
  );
};

export default memo(PdpLayoutWrapper);
