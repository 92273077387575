import {useCurrentSlug} from './useCurrentSlug';
import {useDrugNameBySetId} from './useDrugNameBySetId';

export function useCurrentDrug(): ICurrentDrug {
  const {setId, slug} = useCurrentSlug();
  return {...useDrugNameBySetId(setId), slug};
}

export interface ICurrentDrug {
  slug: string | string[] | undefined;
  drugName: string;
  genericName: string;
  drugClassName: string;
  labelerName: string;
  isLoading: boolean;
  setId: string;
}
