import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useMemo} from 'react';
import HeroIcons from '../HeroIcons';
import BoxedWarning from './BoxedWarning';
import CTAMenuV3 from './CTAMenuV3';
import Dosages from './Dosages';
import Otc from './OTC';
import PrescriberAI from './PrescriberAI';
import RemsInfo from './RemsInfo';
import {HEADER_REMS_INFO} from '@/constants/flags';
import {useMobileContentContext, useUserAgentContext, useFlag} from '@/context';
import {useIsOtc} from '@/hooks';

export interface PdpHeaderV1Props {
  drugName: string;
  genericName: string;
}

const PdpHeaderV1: FC<PdpHeaderV1Props> = ({drugName, genericName}) => {
  const {isMobile} = useUserAgentContext();
  const {showCTAMenu} = useMobileContentContext();
  const isOtc = useIsOtc();

  const showRemsInfo = useFlag(HEADER_REMS_INFO);

  const renderGenericName = useMemo(
    () => drugName.toLocaleLowerCase() !== genericName.toLocaleLowerCase(),
    [drugName, genericName],
  );
  return (
    <>
      <div id="pdp-header-v1">
        <div className="flex xs-only:flex-col sm:flex-row items-center justify-between mb-4 md:mb-3 mt-5">
          <div className="flex flex-wrap xs-only:w-full items-center justify-start">
            <Text
              as={isMobile ? 'headline-md' : 'headline-xl'}
              casing="title"
              className="xs-only:w-auto xs-only:mr-1 sm-only:w-full sm-only:!text-[30px] md:mr-4 md:w-auto">
              {drugName}
            </Text>
            {renderGenericName ? (
              <Text
                as="body-md"
                weight="bold"
                className="text-neutral-secondary-alt mr-1">
                ({genericName})
              </Text>
            ) : null}
            <div className="xs-only:w-full xs-only:mt-3">
              <Dosages />
            </div>
          </div>
          <div className="xs-only:mt-3 flex items-center space-x-1 xs-only:justify-between sm:justify-end xs-only:w-full md:w-[40%] xs-only:overflow-x-auto">
            <div className="flex items-center gap-x-2 xs-only:flex-row-reverse">
              {showRemsInfo && <RemsInfo />}
              {isOtc ? <Otc /> : null}
              <BoxedWarning />
            </div>
            <HeroIcons drugName={drugName} />
          </div>
        </div>
        <div className={clsx('xs-only:mb-4 mt-6', {invisible: !showCTAMenu})}>
          <CTAMenuV3 />
        </div>
      </div>
      {isMobile ? <PrescriberAI /> : null}
    </>
  );
};

export default PdpHeaderV1;
