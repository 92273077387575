/*
**********
JAMES HIGGINS SHOULD BE NOTTIFIED EVERY TIME THIS FILE CHANGES
**********
*/

import {IResult} from '@/interfaces';

// CUSTOM EVENTS
export const CEVENT_ASSIGN_AND_SHARE = 'assign_and_share';
export const CEVENT_BRANDS_SEARCH_RESULT_ADDED = 'brands_search_result_added';
export const CEVENT_BUTTON_CLICKED = 'buttonClicked';
export const CEVENT_CHECK_FOR_INTERACTIONS = 'checkForInteractions';
export const CEVENT_COMPARE_DRUG_LABEL_CHANGE = 'compareDrugLabelChange';
export const CEVENT_COMPARE_POPULAR_ALTERNATIVES = 'comparePopularAlternatives';
export const CEVENT_COVERAGE_PATIENT_INSURANCE_START = 'patientInsuranceStart';
export const CEVENT_COVERAGE_PATIENT_INSURANCE_MCO = 'patientInsuranceMCO';
export const CEVENT_COVERAGE_PATIENT_INSURANCE_CHANNEL =
  'patientInsuranceChannel';

export const CEVENT_COVERAGE_DO_NOT_KNOW_EMPLOYER =
  'coverage_do_not_know_employer';
export const CEVENT_COVERAGE_EMPLOYER_INPUT = 'coverage_start_employer';
export const CEVENT_COVERAGE_MCO_INPUT = 'coverage_start_no_employer';
export const CEVENT_COVERAGE_MCO_SELECTED = 'coverage_mco_selected';
export const CEVENT_COVERAGE_EMPLOYER_SELECTED = 'coverage_employer_selected';
export const CEVENT_COVERAGE_CHANNEL_SELECTED = 'coverage_channel_selected';
export const CEVENT_COVERAGE_INSURANCE_TYPE_SELECTED = 'coverage_type_selected';
export const CEVENT_COVERAGE_SEARCH = 'coverage_search';

export const CEVENT_COVERAGE_RESULTS_REPEAT_NPI =
  'coverage_show_results_repeat_npi';
export const CEVENT_COVERAGE_RESULTS_NEW_NPI = 'coverage_show_results_new_npi';
export const CEVENT_COVERAGE_RESULTS_WITHOUT_NPI =
  'coverage_show_results_no_npi';

/** TODO delete this event CEVENT_COVERAGE_RESULTS_WITH_NPI when delete the old version of coverage */
export const CEVENT_COVERAGE_RESULTS_WITH_NPI = 'coverage_show_results_npi';

export const CEVENT_COVERAGE_TERMS = 'coverage_terms';
export const CEVENT_COVERAGE_PATIENT_INSURANCE_TYPE = 'patientInsuranceType';
export const CEVENT_COVERAGE_PATIENT_INSURANCE = 'patientInsuranceSearch';
export const CEVENT_COVERAGE_NPI_NUMBER_AUTH = 'npiNumberAuth';
export const CEVENT_COVERAGE_NEXT_FINANCIAL = 'coverage_next_financial';
export const CEVENT_COVERAGE_NEXT_PRIORAUTH = 'coverage_next_priorauth';

export const CEVENT_DDI_NPI_NUMBER_AUTH = 'ddiNpiNumberAuth';
export const CEVENT_DDI_NEW_NPI = 'ddi_newnpi';
export const CEVENT_DDI_REPEAT_NPI = 'ddi_returningnpi';
export const CEVENT_COVERAGE_NEXT_STEPS = 'nextSteps';
export const CEVENT_CREATE_FREE_ACCOUNT = 'createFreeAccount';
export const CEVENT_DDI_CHECK = 'check_drug_interactions';
export const CEVENT_DDI_INTERACTIONS = 'view_potential_interactions';
export const CEVENT_DDI_NONPI = 'ddi_nonpi';
export const CEVENT_DROPDOWN = 'dropDown';
export const CEVENT_DRUG_INTERACTION_RESULT = 'drugInteractionResult';
export const CEVENT_DRUG_LABEL_CHANGE = 'drugLabelChange';
export const CEVENT_DRUG_RESOURCES = 'drugResources';
export const CEVENT_NAVIGATE = 'navigate';
export const CEVENT_PRIOR_AUTH_SEARCH = 'prior_auth_search';
export const CEVENT_SALES_REP_CONTACT = 'salesRepContact';
export const CEVENT_SEARCH = 'search';
export const CEVENT_SEND_AND_JOIN = 'send_and_join';
export const CEVENT_SIGNUP_REGISTER_EMAIL = 'signup_register_email';
export const CEVENT_SIGNUP_COMPLETE = 'signup_complete';
export const CEVENT_SPLIT_EXPERIMENT = 'split_experiment';
export const CEVENT_SPLIT_IMPRESSION = 'split_impression';
export const CEVENT_START_ANOTHER_SEARCH = 'startAnotherSearch';
export const CEVENT_CHECK_DOSAGES_AND_STRENGTHS = 'check_dosages_and_strengths';
export const CEVENT_NPI = 'npi';

export const CEVENT_LOGIN = 'login';
export const CEVENT_REP_LOGIN = 'rep_login';

export const CEVENT_REP_CHECK_IN = 'rep_check_in';

export const CEVENT_LIKE_DRUG = 'like_drug';
export const CEVENT_DISLIKE_DRUG = 'dislike_drug';

export const CEVENT_SHARE_BRAND_PAGE = 'share_brand_page';
export const CEVENT_DRUG_SHARED = 'share_drug';
export const CEVENT_BRAND_RSC_SHARED = 'brand_resource_shared';
export const CEVENT_BRAND_RSC_CLICK = 'brand_resource_click';
export const CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE =
  'brand_resource_continue_to_website_click';
export const CEVENT_BRAND_RSC_ABANDONMENT = 'brand_resource_abandonment_click';

export const CEVENT_LEARN_MORE_BTN_CLICKED = 'learn_more_button_clicked';
export const CEVENT_VIEW_MORE_BTN_CLICKED = 'view_more_button_clicked';
export const CEVENT_VIEW_ALL_BTN_CLICKED = 'view_all_button_clicked';

export const CEVENT_SIDEPANEL_CTA = 'side_panel_cta';
export const CEVENT_SAMPLES_ELIGIBILITY_CTA = 'samples_eligibility_cta_click';
export const CEVENT_PAYWALL_REGISTER = 'paywall_register_popup';

export const CEVENT_CONTACT_MED_SCIENCE_LIAISON = 'contactMedScienceLiason';
export const CEVENT_REPORT_ADVERSE_EVENT = 'reportAdverseEvent';
export const CEVENT_USER_INVITED = 'user_invited';
export const CEVENT_INVITATION_LINK = 'invitation_link';
export const CEVENT_INVITATION_ACCEPTED = 'invitation_accepted';
export const CEVENT_SIGNUP_FOR_UPDATES = 'signup_for_updates';

export const CEVENT_VIEW_BY_PAYER = 'viewByPayer';
export const CEVENT_VIEW_ALL_FORMS = 'viewAllForms';

export const CEVENT_PA_RESOURCE_VIEW = 'resourceView';

export const CEVENT_HOVER_QUESTION = 'commonQuestionHovered';

export const CEVENT_USER = 'user';

export const CEVENT_PUBMED_IS_HCP = 'pubmed_is_hcp';

// Affordability
export const CEVENT_AFFORDABILITY_FINDER = 'affordabilityFinder';
export const CEVENT_FINANCIAL_ASSISTANCE_INSURANCE =
  'financial_assistance_insurance';
export const CEVENT_FINANCIAL_ASSISTANCE_INFO = 'financial_assistance_info';
export const CEVENT_FINANCIAL_ASSISTANCE_ELIGIBILITY =
  'financial_assistance_eligibility';

// New Affordability finder
export const CEVENT_FINANCIAL_INVESTIGATE_START = 'financial_investigate_start';
export const CEVENT_FINANCIAL_INVESTIGATE_RESOURCE =
  'financial_investigate_resource';
export const CEVENT_FINANCIAL_INVESTIGATE_APPLY = 'financial_investigate_apply';

//PDP User Identification Survey
export const CEVENT_SURVEY_IS_PRESCRIBER = 'survey_is_prescriber';
export const CEVENT_SURVEY_IS_NOT_PRESCRIBER = 'survey_is_not_prescriber';
export const CEVENT_SURVEY_IS_NOT_HCP = 'survey_is_not_hcp';
export const CEVENT_SURVEY_DISPLAY = 'survey_display';
export const CEVENT_SURVEY_ABANDONED = 'survey_abandoned';

export type DlvCoveragePatientInsurance = {
  insuranceName: string;
  coverage: boolean;
};

export type DlvCoverageNextSteps = {
  resourceName:
    | 'prior_authorization'
    | 'financial_assistance'
    | 'letter_of_medical_necessity';
};

export type DlvCoverageNPINumberAuth = {
  npi_auth: string;
};

export type DlvDDINpiCapture = {
  drug_name: string;
};

export type DlvLogin = {
  userId: string;
  emailDomain: string;
};
export type DlvSignupRegisterEmail = {
  emailDomain: string;
};

export type DlvSignupComplete = {
  userId: string;
  hcpCredentials: string;
  prescriptionWriter: 'yes' | 'no';
  practiceName: string;
  emailDomain: string;
};

export type DlvSignupForUpdates = {
  emailDomain: string;
  drugName: string;
  pharmaCompany: string;
  drugType: string;
  drugSpecialties: string;
  isEnhanced: string;
  setId: string;
};

export type DlvLikeDrug = {
  drugName: string;
  pharmaCompany: string;
  drugType: string;
  drugSpecialties: string;
  isEnhanced: string;
  setId: string;
};

export type DlvNavigate = {
  pageSection: string;
  userKey?: string;
  experimentName?: string;
  treatment?: boolean;
};

export type DlvAssignAndShare = {
  resourceName: string;
  setId: string;
  drugName: string;
  emailDomain: string;
  redirectUrl: string;
  resourceSection: string;
  resourceParentSection: string;
};

export type DlvLearnMoreBtnClicked = {
  resourceSection?: string;
};

export type DlvViewMoreBtnClicked = {
  resourceSection: string;
};

export type DlvDrugResources = {
  drugName: string;
  resourceName: string;
};

export type DlvDrugLabelChange = {
  pharmaCompany: string;
  drugName: string;
  setId: string;
  userId: string;
  drugType: string;
  isEnhanced: string;
  drugSpecialties: string;
};

export type DlvSearch = {
  pharmaCompany: string;
  search: string;
  userKey: string;
};

export type DlvButtonClicked = {
  id: string;
};

export type DlvCoverageRestSearch = {
  drugName: string;
  insuranceState: string;
  insuranceName: string;
};

export type DlvBrandRscShared = {
  drugName: string;
  resourceName: string;
  emailDomain: string;
  shareType: string;
  link: string;
  resourceSection?: string;
  resourceParentSection?: string;
};

export type DlvBrandRscContinueToWebsite = {
  drugName: string;
  resourceName: string;
  link: string;
  resourceSection?: string;
  resourceParentSection?: string;
  userKey?: string;
  experimentName?: string;
  treatment?: boolean;
};

export type DlvBrandRscAbandonment = {
  drugName: string;
  resourceName: string;
  treatment?: boolean;
  firePaywall: boolean;
  experimentName?: string;
  userKey?: string;
  resourceParentSection?: string;
  resourceSection?: string;
};

export type DlvComparePopularAlts = {
  title: string;
};

export type DlvCompareDrugLabelChange = {
  principalSlug: string;
  principalDrugName: string;
  secondarySlug: string;
  secondaryDrugName: string;
};

export type DlvRepContactPhone = {
  phoneNumber: string;
};

export type DlvRepContactEmail = {
  emailDomain: string;
};

export type DlvRepContact = {
  repContactType: 'email' | 'phone';
  repName: string;
  pharmaCompany: string;
  drugName: string;
  drugType: string;
  isEnhanced: string;
  drugSpecialties: string;
};

export type DlvDrugInteraction = {
  drug1: IResult;
  drug2: IResult;
};

export type DlvSplitExperiment = {
  name: string;
  treatment: boolean;
};

export type DlvSplitImpression = {
  name: string;
  treatment: boolean;
};

export type DlvDropdown = {
  pageSection: string;
  dropDownText: string;
};

export type DlvSidePanelCTA = {
  buttonName: string;
};

export type DlvRscClick = {
  drugName: string;
  resourceName: string;
  resourceSection?: string;
  resourceParentSection?: string;
  resourceImage?: string;
  redirectUrl?: string;
  firePaywall: boolean;
  userKey: string;
  experimentName?: string;
  treatment?: boolean;
};

export type DlvPaywallRegister = {
  drugName: string;
  emailDomain: string;
  pharmaCompany: string;
  isEnhanced: string;
  drugSpecialties: string;
};

export type DlvDrugShared = {
  drugName: string;
  setId: string;
  pharmaCompany: string;
  drugType: string;
  isEnhanced: string;
  drugSpecialties: string;
};

export type DlvDDIPotentialInteractions = {
  checkDrugs: string;
  hasInteraction: boolean;
};

export type DlvDDICheck = {
  drugName: string;
  pharmaCompany: string;
  drugSpecialties: string;
  userKey?: string;
  experimentName?: string;
  treatment?: boolean;
};

export type DlvDrugInfo = {
  drugName: string;
  pharmaCompany: string;
  drugType: string;
  drugSpecialties: string;
  isEnhanced: string;
};

export type DlvInvitation = {
  emailDomain: string;
};

export type DlvViewByPayer = {
  drugName: string;
  resourceSection: string;
  resourceParentSection: string;
};

export type DlvViewAllForms = {
  drugName: string;
  payer: string;
};

export type DlvPaResourceView = {
  drugName: string;
  resourceName: string;
};

export type DlvHoverQuestions = {
  setId: string;
  drugName: string;
  pharmacyName: string;
  isEnhanced: string;
  drugType: string;
  drugSpecialties: string;
  questionText: string;
};

export interface DLVUser {
  user: IUser;
}

export interface IUser {
  email?: string;
  npi?: string;
  firstName?: string;
  lastName?: string;
}

export interface DlvAffordabilityFinder {
  drugName: string;
  retailValue: string | number;
}

export interface DlvFinancialAssistanceInsurance {
  drugName: string;
  insuranceName: string;
  retailValue: string | number;
}

export interface DlvFinancialAssistanceInfo {
  drugName: string;
  annualIncome: string;
  patientHousehold: string;
  patientStateException: string;
  retailValue?: number | number;
}

export interface DlvFinancialAssistanceEligibility {
  drugName: string;
  programName: string;
  retailValue: string;
}

export const IframeMsgCodes = {
  CustomEvent: 'ppt-custom-event',
};

export interface DLVNpi {
  npi: string;
}

export interface DlvFinancialInvestigateStart {
  drugName: string;
  programName: string;
}

export interface DlvFinancialInvestigateResource {
  drugName: string;
  pdfLink: string;
}

export interface DlvFinancialInvestigateApply {
  drugName: string;
  programName: string;
  phoneNumber: string;
  applicationLink: string;
}
