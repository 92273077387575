import {useCallback, useMemo} from 'react';
import {useEnhancedContent} from '@/queries';
import {useLabel} from '@/queries/useLabel';
import {capitalizeFirstLetter} from '@/utils/string';

export function useDrugNameBySetId(setId: string) {
  const {data: product, isLoading} = useLabel(setId);
  const {data: enhancedData, isLoading: isLoadingEnhanced} =
    useEnhancedContent(setId);

  const findName = useCallback(
    (tty: string) => {
      switch (tty) {
        case 'BN':
        case 'PIN':
          return product?.names?.find((name) => name.tty === tty)?.name ?? '';
        case 'IN':
          return (
            product?.names?.find((name) => name.tty === 'MIN')?.name ??
            product?.names
              ?.filter((name) => name.tty === tty)
              .map((name) => name.name)
              ?.join(', ') ??
            ''
          );
        default:
          return '';
      }
    },
    [product?.names],
  );

  return useMemo(() => {
    const drugName =
      enhancedData?.enhancedContent?.brandName ||
      capitalizeFirstLetter(
        (findName('BN')
          ? findName('BN')
          : product?.label?.title ?? findName('IN')
        ).toLocaleLowerCase(),
      );
    const pinName = findName('PIN');
    const genericName = pinName !== '' ? pinName : findName('IN');

    const drugClassName =
      product?.classes?.find(
        (drugClass) => drugClass.type.toLowerCase() === 'epc',
      )?.name ?? '';
    const labelerName = product?.label?.labelerName ?? '';

    return {
      drugName,
      genericName,
      drugClassName,
      labelerName,
      isLoading: isLoading || isLoadingEnhanced,
      setId,
    };
  }, [
    enhancedData?.enhancedContent?.brandName,
    findName,
    product?.label?.title,
    product?.label?.labelerName,
    product?.classes,
    isLoading,
    isLoadingEnhanced,
    setId,
  ]);
}
