import path from 'path';
import {getFsPromises} from './nodeUtils';
import {LOCAL_FILES_PATH} from '@/constants/global';
import {read} from '@/services/api';

export interface ICdnDataResponse<T> {
  data?: T;
  source?: string;
  errorMessage?: string;
}

export async function getDataFromLocalPath<T>(
  filepath: string,
): Promise<ICdnDataResponse<T>> {
  // Check if the code is running on the client side
  if (typeof window !== 'undefined') {
    return {errorMessage: 'Cannot read local file on client'};
  }

  let localFilePath = LOCAL_FILES_PATH;

  // Check if the path is an absolute path so for development purposes we can have a local file that we can use
  if (!path.isAbsolute(localFilePath)) {
    localFilePath = path.resolve(localFilePath);
  }

  // we build the file path based on the environment
  localFilePath = path.join(localFilePath, filepath);

  try {
    // Dynamic import
    const fs = await getFsPromises();
    const fileContent = await fs.readFile(localFilePath, 'utf-8');

    const data: T = JSON.parse(fileContent);
    return {data, source: 'local file'};
  } catch (error) {
    return {errorMessage: 'Failed to read local file'};
  }
}

export async function getDataFromCdn<T>(
  filepath: string,
): Promise<ICdnDataResponse<T>> {
  try {
    const cdnFilePath = path.join('ppwebapp', filepath);
    const data = await read<T>(cdnFilePath, {}, {}, 'cdn');
    return {data, source: 'cdn'};
  } catch (error) {
    return {errorMessage: 'Failed to fetch data from CDN'};
  }
}

export async function getDataFromLocalOrCdn<T>(
  filepath: string,
): Promise<ICdnDataResponse<T>> {
  let response = await getDataFromLocalPath<T>(filepath);
  if (response.errorMessage) {
    response = await getDataFromCdn<T>(filepath);
  }

  return response;
}
